<template>
  <v-responsive
    min-height="94vh"
    class="d-flex align-center mt-n16 image-background"
    :style="`background-image: url('${background[2]}')`"
  >

    <v-container class="">
      <v-row class="d-flex justify-center">

        <v-col col xl="4" md="6" sm="12" class="box-10 call-action-glass py-6 px-8 elevation-4">
          <h1 class="display-2 text-center">Inicia Sesión</h1>
          <hr class="red">

          <GlobalAlerts 
            v-if="errorStatus === 'ValidationError'" 
            cfgColorBg="red darken-2"
            cfgIcon="mdi-skull-crossbones"
            cfgMessage="El usuario y/o la contraseña no coinciden"
            />

          <GlobalAlerts
            v-if="errorStatus === 'ApplicationError'"
            cfgColorBg="amber lighten-2"
            cfgIcon="mdi-alert"
            cfgVariant="light"
            cfgMessage="Confirma tu correo electrónico antes de continuar"
            />

          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >

            <v-text-field
              label="Correo electrónico: "
              v-model="user.username"
              :rules="rules.email"
              filled
            ></v-text-field>

            <v-text-field
              filled
              v-model="user.password"
              :rules="rules.password"
              :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showpass ? 'text' : 'password'"
              label="Contraseña"
              hint="Coloca al menos 8 caracteres"
              counter
              @click:append="showpass = !showpass"
            ></v-text-field>

            <div class="my-2">
              <v-btn
                :loading="loading"
                x-large
                color="rojo"
                dark
                class="float-right"
                @click="handleLogin()"
              >
                Iniciar Sesión
                <v-icon dark>
                  mdi-account-arrow-right
                </v-icon>
              </v-btn>
            </div>

          </v-form>

        </v-col>
      </v-row>

      
    
    </v-container>
  </v-responsive>
</template>

<script>
import GlobalAlerts from '@/components/global/_Alerts.vue'
import User from '@/models/user';

export default {
  name: 'Login',
  
  components: {
    GlobalAlerts
  },

  data() {
    return {
      user: new User('', ''),
      loader: null,
      loading: false,
      message: '',
      valid: true,
      showpass: false,
      errorStatus: '',

      rules: {
        email: [
          v => !!v || 'El correo electrónico obligatorio',
          v => /.+@.+/.test(v) || 'Con es un correo valido',
        ],
        password: [
          v => !!v || 'La Contraseña es obligatoria',
        ]
      },

      background: [
        '/assets/images/2.jpg',
        '/assets/images/3.jpg',
        '/assets/images/4.jpg'
      ],

    };
  },
  
  watch: {
    loader () {
      const l = this.loader
      this[l] = !this[l]

      setTimeout(() => (this[l] = false), 2000)

      this.loader = null
    },
  },

  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;

    }
  },

  mounted(){
    console.log("🚀 ~ file: Login.vue ~ line 123 ~ mounted ~ this.loggedIn", this.loggedIn)
  },

  created() {
    if (this.loggedIn) {
      this.$router.push('/profile');
    }
  },
  
  methods: {
    handleLogin() {
      //this.loader = 'loading';

      this.$refs.form.validate();
      if(this.$refs.form.validate() === true){
      
        if (this.user.username && this.user.password) {
          
          this.$store.dispatch('auth/login', this.user).then(
            () => {
              this.loader = 'loading';
              console.log("🚀 ~ file: Login.vue ~ line 173 ~ handleLogin ~ this.loader", this.loader)
              this.$router.push('/user/profile');
            },
            error => {
              this.loader = 'loading';
              this.message =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
              this.errorStatus = this.message.error.name
              console.log("🚀 ~ file: Login.vue ~ line 182 ~ handleLogin ~ this.errorStatus", this.errorStatus)
            }
            
          );
        }

      }


/*

      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        if (this.user.username && this.user.password) {
          this.$store.dispatch('auth/login', this.user).then(
            () => {
              this.$router.push('/profile');
            },
            error => {
              this.loading = false;
              this.message =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
            }
          );
        }
      });
*/


    }
  }
};
</script>

<style scoped>

</style>
