<template>
  <section class="roles-menu">
    <v-btn small to="/" text class="mr-2">
      <v-icon left>mdi-home</v-icon>
      Inicio
    </v-btn>

    <v-btn v-if="user_me.isActive" text small to="/dashboard" class="mr-2">
      <v-icon left>mdi-view-dashboard</v-icon>
      Dashboard
    </v-btn>

    <v-btn small to="/user/profile" text class="mr-2">
      <v-avatar size="38" class="mr-1">
        <img
          :src="`${path.storage_files + user_me.avatar.url}`"
          :alt="`${
            user_me.fullName ? user_me.fullName : currentUser.user.username
          }`"
        />
      </v-avatar>
      {{ user_me.fullName ? user_me.fullName : currentUser.user.username }}
      <br />
      <!--small>{{ currentUser.user.rme_json.name }}</small-->
    </v-btn>

    <v-btn
      color="light-green darken-1"
      @click.prevent="logOut"
      x-small
      fab
      class="mr-2"
    >
      <v-icon>mdi-account-arrow-right-outline</v-icon>
    </v-btn>

    <v-btn color="light-green darken-1" href="/help" fab x-small class="mr-2">
      <v-icon>mdi-help</v-icon>
    </v-btn>
  </section>
</template>
<script>
import UserService from "@/services/user.service";
import MenuOrganizationComponent from "@/components/roles/organizacion/Menu.vue";

export default {
  name: "MenuRolesComponent",

  components: {
    MenuOrganizationComponent,
  },

  data: () => ({
    user_me: {
      avatar: {},
      role: {},
      rme_json: {},
    },
    path: {
      base_url: process.env.VUE_APP_BASE_URL,
      endpoint: process.env.VUE_APP_ENDPOINT,
      storage_files: process.env.VUE_APP_STORAGE_FILES,
    },
  }),

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },

  mounted() {
    UserService.loadUserMe().then((response) => {
      this.user_me = response.data;
    });
  },

  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/auth/login");
    },
  },
};
</script>
