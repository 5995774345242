<template>
  <span class="organization-menu">
    <v-btn to="/dashboard" text class="mr-1">
      <v-icon>mdi-view-dashboard</v-icon> Dashboard
    </v-btn>
  </span>
</template>
<script>
export default {
  name: "MenuOrganizationComponent",

  components: {},

  data: () => ({}),

  mounted() {},

  computed: {},

  methods: {},
};
</script>
