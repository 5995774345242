import Vue from "vue";
import VueRouter from "vue-router";
//import HomeView from '../views/HomeView.vue'

/*
 * Area Publica
 */
import Home from "@/views/Home.vue";
import Login from "@/views/auth/Login.vue";
import ResetPassword from "@/views/auth/ResetPassword.vue";
import EmailConfirmation from "@/views/auth/EmailConfirmation.vue";
import Register from "@/views/auth/Register.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Login,
  },

  /* Auth */
  {
    path: "/auth/login",
    component: Login,
  },
  {
    path: "/auth/register",
    component: Register,
  },
  {
    path: "/auth/reset-password",
    name: "AuthResetPassword",
    component: ResetPassword,
  },
  {
    path: "/auth/email-confirmation/:token?",
    name: "EmailConfirmation",
    component: EmailConfirmation,
  },

  /* User > Profile */
  {
    path: "/user/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "@/views/auth/Profile.vue"),
  },

  /* Dashboard */
  {
    path: "/dashboard",
    name: "dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard.vue"),
  },
  

  /* Reportes */
  {
    path: "/reports/all/:category?",
    name: "reportsAll",
    component: () =>
      import(/* webpackChunkName: "reports" */ "@/views/reports/All.vue"),
  },
  {
    path: "/reports/add/:category",
    name: "reportsAdd",
    component: () =>
      import(/* webpackChunkName: "reports" */ "@/views/reports/Add.vue"),
  },
  {
    path: "/reports/download/:category",
    name: "reportsDown",
    component: () =>
      import(/* webpackChunkName: "reports" */ "@/views/reports/Download.vue"),
  },
 /* Reportes validador*/

 {
  path: "/RmeValidador/all/:category?",
  name: "reportsAllVal",
  component: () =>
    import(/* webpackChunkName: "reports" */ "@/components/roles/RmeValidador/All.vue"),
},

{
  path: "/RmeValidador/download/:category",
  name: "reportsAllDown",
  component: () =>
    import(/* webpackChunkName: "reports" */ "@/components/roles/RmeValidador/Download.vue"),
},

/* Reportes Administrador */
{
  path: "/SysAdmin/reports/all/:category?",
  name: "reportsAllAdmin",
  component: () =>
    import(/* webpackChunkName: "reports" */ "@/components/roles/SysAdmin/reports/All.vue"),
},

{
  path: "/SysAdmin/download/:category",
  name: "reportsAdminDown",
  component: () =>
    import(/* webpackChunkName: "download" */ "@/components/roles/SysAdmin/Download.vue"),
},

{
  path: "/SysAdmin/reports/Comparative",
  name: "comparative",
  component: () =>
    import(/* webpackChunkName: "comparative" */ "@/components/roles/SysAdmin/reports/Comparative.vue"),
},

{
  path: "/SysAdmin/dashboard",
  name: "dashboard",
  component: () =>
    import(/* webpackChunkName: "dashboard" */ "@/components/roles/SysAdmin/Dashboard.vue"),
},
  /* Pages */
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/", //process.env.VUE_APP_BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    "/",
    "/auth/login",
    "/auth/register",
    "/auth/reset-password",
    "/auth/email-confirmation",
  ];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");

  if (authRequired && !loggedIn) {
    next("/auth/login");
  } else {
    next();
  }
});

export default router;
