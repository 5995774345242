import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VeeValidate from "vee-validate";
import vuetify from "./plugins/vuetify";
import VSwitch from "v-switch-case";
import VueLocalStorage from "vue-localstorage";

import VueMoment from "vue-moment";
import moment from "moment-timezone";
require("moment/locale/es-mx");

/* Custom Style */
import "./assets/css/gobmx.css";
import "./assets/css/styles.css";

Vue.config.productionTip = false;
Vue.use(VeeValidate);
Vue.use(VSwitch);
Vue.use(VueLocalStorage);
//Vue.use(require('vue-moment'))
Vue.use(VueMoment, {
  moment,
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
